import styled from "styled-components"

export const StyledFuelGauge = styled.div`
  display: block;
  width: 8.6%;
  height: 10.6%;
  position: absolute;

  & > .screen {
    height: 100%;
    width: auto;
    display: block;
  }

  & > .btn {
    display: block;
    position: absolute;
    padding: 0 0;
    border: none;
    background: none;
    height: 23.2%;
    width: auto;
    left: 25.8%;
    top: 69.2%;

    &.pressed > img.icon {
      filter: brightness(70%);
    }

    & > img.icon {
      transition: filter 0.2s ease-in-out;
      height: 100%;
      width: auto;
      display: block;
    }
  }
`
