import React from "react"
import PropTypes from "prop-types"

import HomeButtonDisplay from "./HomeButtonDisplay"

const HomeButton = ({ onButtonPress }) => {
  return <HomeButtonDisplay onButtonPress={onButtonPress} />
}

HomeButton.propTypes = { onButtonPress: PropTypes.func }

export default HomeButton
