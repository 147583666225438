import React from "react"

import { StyledMaintenanceHatch } from "./MaintenanceHatchStyles"
import HatchOpen from "../../../images/mission_control/maintenance/WartungTürOffen.png"
import HatchClosed from "../../../images/mission_control/maintenance/WartungTürGeschlossen.png"

const MaintenanceHatchDisplay = ({isOpen}) => {
  return (
    <StyledMaintenanceHatch className={`maintenance-hatch ${isOpen ? "open" : "closed"}`}>
      <img className="hatch" src={isOpen ? HatchOpen : HatchClosed} draggable={false} />
    </StyledMaintenanceHatch>
  )
}

export default MaintenanceHatchDisplay
