import styled from "styled-components"

export const StyledTrophyRack = styled.div`
  display: block;
  width: 22.8%;
  height: 30.5%;
  position: absolute;

  & > img.rack {
    width: 100%;
    height: 100%;
    display: block;
  }
`
