import styled from "styled-components"
import { cover, transparentize, fluidRange } from "polished"

export const StyledLaunchButton = styled.button`
  display: block;
  border: none;
  background: none;
  width: 7%;

  &,
  & > * {
    user-select: none;
    -moz-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
    -webkit-touch-callout: none;
  }

  @keyframes push-down {
    from {
      transform: translateY(0%);
    }
    to {
      transform: translateY(25%);
    }
  }

  & > .launch-btn {
    width: 100%;
    height: auto;
    z-index: 5;
    position: relative;
    display: block;
    /* animation: push-down 1s ease-in-out reverse; */
    transition: transform 0.1s ease-in-out, filter 0.2s ease-in-out;
  }

  &.pressed > .launch-btn {
    /* animation: push-down 0.2s ease-in-out; */
    transform: translateY(30%);
    filter: brightness(70%);
  }

  & > .launch-base {
    width: 100%;
    height: auto;
    z-index: 4;
    position: relative;
    display: block;
    margin-top: -40%;
  }
`
