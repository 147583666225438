import React from "react"
import PropTypes from "prop-types"

import HomeScreenDisplay from "./HomeScreenDisplay"

const HomeScreen = () => {
  return <HomeScreenDisplay />
}

export default HomeScreen
