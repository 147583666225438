import React, { useState } from "react"

import { StyledFuelGauge } from "./FuelGaugeStyles"
import FuelScreenRed from "../../../images/mission_control/fuel/BasisaufgabeScreenRed.png"
import FuelScreenGreen from "../../../images/mission_control/fuel/BasisaufgabeScreenGreen.png"
import FuelButtonRed from "../../../images/mission_control/fuel/BasisaufgabeRedLight_Dynamic.png"
import FuelButtonGreen from "../../../images/mission_control/fuel/BasisaufgabeGreenLight_Dynamic.png"

const FuelGaugeDisplay = ({ onButtonPress }) => {
  const [buttonPressed, setButtonPressed] = useState(false)

  const handleButtonPress = () => {
    setButtonPressed(true)
    if (onButtonPress) onButtonPress()
  }

  const handleButtonRelease = () => {
    setButtonPressed(false)
  }

  return (
    <StyledFuelGauge className={`fuel-gauge`}>
      <img className="screen" src={FuelScreenRed} draggable={false} />
      <button
        className={`btn${buttonPressed ? " pressed" : ""}`}
        type="button"
        onTouchStart={handleButtonPress}
        onMouseDown={handleButtonPress}
        onTouchEnd={handleButtonRelease}
        onMouseUp={handleButtonRelease}
      >
        <img className="icon" src={FuelButtonGreen} draggable={false} />
      </button>
    </StyledFuelGauge>
  )
}

export default FuelGaugeDisplay
