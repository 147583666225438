import styled from "styled-components"

export const StyledRepairButton = styled.button`
  display: block;
  border: none;
  background: none;
  width: 6.3%;
  height: 7.6%;
  position: absolute;
  padding: 0 0;

  & > .icon {
    height: 91.4%;
    width: 96.1%;
    z-index: 5;
    position: absolute;
    display: block;
    top: 0;
    left: 3.9%;
    transition: transform 0.1s ease-in-out, filter 0.2s ease-in-out;
  }

  &.pressed > .icon {
    transform: translate(-3.9%, 8.6%);
    filter: brightness(70%);
  }

  & > .base {
    height: 91.4%;
    width: 96.1%;
    z-index: 4;
    position: absolute;
    display: block;
    top: 8.6%;
    left: 0%;
  }
`
