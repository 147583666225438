import styled from "styled-components"
import { cover, transparentize, fluidRange } from "polished"

import ScreenFrame from "../../../images/mission_control/destination/DestinationScreen_Frame.png"

export const StyledDestinationScreen = styled.div`
  display: block;
  width: 16.4%;
  height: auto;
  text-align: center;
  padding-left: 0.7vi;
  padding-right: 0.7vi;
  padding-top: 1.3vb;
  padding-bottom: 1.3vb;

  & > img.display {
    width: 100%;
    height: auto;
    z-index: 5;
    position: relative;
    display: block;
    border: 0.8vb solid #2a7a88;
    border-radius: 6%;
    background-color: #2a7a88;
  }
`
