import styled from "styled-components"

export const StyledRepairScreen = styled.div`
  display: flex;
  width: 8.9%;
  height: 15.1%;
  justify-content: center;
  align-items: center;

  @keyframes blink {
    from {
      opacity: 1;
    }
    to {
      opacity: 0;
    }
  }

  & > img.display {
    height: 11vb;
    width: auto;
    z-index: 5;
  }

  & > img.flame {
    position: absolute;
    width: 36.4%;
    height: 53.4%;
    margin: auto;
    z-index: 6;
    animation: blink 0.4s ease-in-out infinite alternate;
  }
`
