import React from "react"
import PropTypes from "prop-types"

import RepairButtonDisplay from "./RepairButtonDisplay"

const RepairButton = ({ onButtonPress }) => {
  return <RepairButtonDisplay onButtonPress={onButtonPress} />
}

RepairButton.propTypes = { onButtonPress: PropTypes.func }

export default RepairButton
