import styled from "styled-components"

export const StyledDashboardScreen = styled.div`
  display: block;
  position: absolute;
  width: 17.9%;
  height: 24%;

  & > img.display {
    height: 100%;
    width: 100%;
  }
`