import React, { useState } from "react"

import { StyledDestinationScreen } from "./DestinationScreenStyles"
import ScreenFrame from "../../../images/mission_control/destination/DestinationScreen_Frame.png"
import StaticScreen from "../../../images/mission_control/destination/DefaultScreen01_Static.png"
import DynamicScreen1 from "../../../images/mission_control/destination/DefaultScreen01_Dynamic01.png"
import DynamicScreen2 from "../../../images/mission_control/destination/DefaultScreen01_Dynamic02.png"
import DynamicScreen3 from "../../../images/mission_control/destination/DefaultScreen01_Dynamic03.png"

const DestinationScreenDisplay = () => {
  return (
    <StyledDestinationScreen className="dest-screen">
      {/* <img className="frame" src={ScreenFrame} draggable={false} /> */}
      <img className="display" src={StaticScreen} draggable={false} />
    </StyledDestinationScreen>
  )
}

export default DestinationScreenDisplay
