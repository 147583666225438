import React from "react"
import PropTypes from "prop-types"

import RepairScreenDisplay from "./RepairScreenDisplay"

const RepairScreen = () => {
  return <RepairScreenDisplay />
}

export default RepairScreen
