import React from "react"
import PropTypes from "prop-types"

import DestinationScreenDisplay from "./DestinationScreenDisplay"

const DestinationScreen = () => {
  return <DestinationScreenDisplay />
}

export default DestinationScreen
