import React, { useState } from "react"

import { StyledRepairButton } from "./RepairButtonStyles"
import RepairButtonIcon from "../../../images/mission_control/repair/ErsatzteileButton_Dynamic.png"
import RepairButtonBase from "../../../images/mission_control/repair/ErsatzteileButtonBase.png"

const RepairButtonDisplay = ({ onButtonPress }) => {
  const [buttonPressed, setButtonPressed] = useState(false)

  const handleButtonPress = () => {
    setButtonPressed(true)
    if (onButtonPress) onButtonPress()
  }

  const handleButtonRelease = () => {
    setButtonPressed(false)
  }

  return (
    <StyledRepairButton
      className={`btn-repair${buttonPressed ? " pressed" : ""}`}
      type="button"
      onTouchStart={handleButtonPress}
      onMouseDown={handleButtonPress}
      onTouchEnd={handleButtonRelease}
      onMouseUp={handleButtonRelease}
    >
      <img className="icon" src={RepairButtonIcon} draggable={false} />
      <img className="base" src={RepairButtonBase} draggable={false} />
    </StyledRepairButton>
  )
}

export default RepairButtonDisplay
