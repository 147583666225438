import styled from "styled-components"

export const StyledHomeScreen = styled.div`
  display: block;
  position: absolute;
  width: 14%;
  height: 20.1%;

  & > img.display {
    height: 100%;
    width: 100%;
  }
`