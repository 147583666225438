import React from "react"
import PropTypes from "prop-types"

import HoloTextDisplay from "./HoloTextDisplay"

const HoloText = () => {
  return <HoloTextDisplay />
}

export default HoloText
