import React from "react"
import PropTypes from "prop-types"

import TrophyRackDisplay from "./TrophyRackDisplay"

const TrophyRack = () => {
  return <TrophyRackDisplay />
}

export default TrophyRack
