import React from "react"
import PropTypes from "prop-types"

import DashboardScreenDisplay from "./DashboardScreenDisplay"

const DashboardScreen = () => {
  return <DashboardScreenDisplay />
}

export default DashboardScreen
