import React, {useState} from "react"
import PropTypes from "prop-types"

import MaintenanceHatchDisplay from "./MaintenanceHatchDisplay"

const MaintenanceHatch = () => {
  const [isOpen, setIsOpen] = useState(false)  
  return <MaintenanceHatchDisplay isOpen={isOpen} />
}

export default MaintenanceHatch
