import styled from "styled-components"

export const StyledRepairLamp = styled.div`
  display: block;
  width: 13.5%;
  height: 24%;
  position: absolute;

  & > img.lamp {
    width: 100%;
    height: 100%;
    z-index: 5;
    display: block;
  }
`
