import React from "react"
import PropTypes from "prop-types"

import MaintenanceScreenDisplay from "./MaintenanceScreenDisplay"

const MaintenanceScreen = () => {
  return <MaintenanceScreenDisplay />
}

export default MaintenanceScreen
