import React from "react"

import { StyledRepairLamp } from "./RepairLampStyles"
import LampOn from "../../../images/mission_control/repair/ErsatzteileLampeAn.png"
import LampOff from "../../../images/mission_control/repair/ErsatzteileLampeAus.png"

const RepairLampDisplay = () => {
  return (
    <StyledRepairLamp className="repair-lamp">
      <img className="lamp" src={LampOn} draggable={false} />
    </StyledRepairLamp>
  )
}

export default RepairLampDisplay
