import styled from "styled-components"

export const StyledMaintenanceLever = styled.button`
  display: block;
  border: none;
  background: none;
  width: 3.5%;
  height: 2.7%;
  position: absolute;
  padding: 0 0;
  transition: transform 1s ease-in-out;

  & > .icon {
    height: 100%;
    width: 100%;
    display: block;
  }

  &.up {
  }

  &.down {
    transform: translate(-1vi, 6vb);
  }
`
