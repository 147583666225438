import React from "react"
import PropTypes from "prop-types"

import LaunchButtonDisplay from "./LaunchButtonDisplay"

const LaunchButton = ({ onButtonPress }) => {
  return <LaunchButtonDisplay onButtonPress={onButtonPress} />
}

LaunchButton.propTypes = { onButtonPress: PropTypes.func }

export default LaunchButton
