import React from "react"

import { StyledDashboardScreen } from "./DashboardScreenStyles"
import DefaultScreen1 from "../../../images/mission_control/dashboard/DashboardDefaultScreen01.png"
import DefaultScreen2 from "../../../images/mission_control/dashboard/DashboardDefaultScreen02.png"
import ScreenBackground from "../../../images/mission_control/dashboard/DashboardScreenBG.png"

const DashboardScreenDisplay = () => {
  return (
    <StyledDashboardScreen className="dashboard-screen">
      <img className="display" src={DefaultScreen1} draggable={false} />
    </StyledDashboardScreen>
  )
}

export default DashboardScreenDisplay
