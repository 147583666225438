import React from "react"

import { StyledMaintenanceScreen } from "./MaintenanceScreenStyles"
import Symbol from "../../../images/mission_control/maintenance/WartungSymbol.png"
import GreenSymbol from "../../../images/mission_control/maintenance/WartungSymbolGreen.png"
import RedSymbol from "../../../images/mission_control/maintenance/WartungSymbolRed.png"

const MaintenanceScreenDisplay = () => {
  return (
    <StyledMaintenanceScreen className="maintenance-screen">
      <img className="symbol" src={Symbol} draggable={false} />
    </StyledMaintenanceScreen>
  )
}

export default MaintenanceScreenDisplay
