import React from "react"
import PropTypes from "prop-types"

import FuelGaugeDisplay from "./FuelGaugeDisplay"

const FuelGauge = ({ onButtonPress }) => {
  return <FuelGaugeDisplay onButtonPress={onButtonPress} />
}

FuelGauge.propTypes = { onButtonPress: PropTypes.func }

export default FuelGauge
