import React from "react"
import PropTypes from "prop-types"

import RepairLampDisplay from "./RepairLampDisplay"

const RepairLamp = () => {
  return <RepairLampDisplay />
}

export default RepairLamp
