import React from "react"

import { StyledTrophyRack } from "./TrophyRackStyles"
import Rack from "../../../images/mission_control/achievements/Pokalregal.png"
import IceTrophy from "../../../images/mission_control/achievements/Trophy_Ice.png"
import MemoryTrophy from "../../../images/mission_control/achievements/Trophy_Memory.png"
import RacingTrophy from "../../../images/mission_control/achievements/Trophy_Racing.png"
import StarTrophy from "../../../images/mission_control/achievements/Trophy_Star.png"
import WordRelationTrophy from "../../../images/mission_control/achievements/Trophy_Wortverwandt.png"
import BreakTrophy from "../../../images/mission_control/achievements/Trophy_Zerlegen.png"

const TrophyRackDisplay = () => {
  return (
    <StyledTrophyRack className="trophy-rack">
      <img className="rack" src={Rack} draggable={false} />
    </StyledTrophyRack>
  )
}

export default TrophyRackDisplay
