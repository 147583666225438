import React, { Component } from "react"
import { Link, navigate } from "gatsby"
import { isMobileSafari } from "react-device-detect"

// Used components
import Navigation from "../../../../components/navigation"
import Content from "../../../../components/content"
import Seo from "../../../../components/seo"
import CurrentUser from "../../../../components/CurrentUser"
import LaunchButton from "../../../../components/MissionControl/LaunchButton"
import DestinationScreen from "../../../../components/MissionControl/DestinationScreen"
import HomeButton from "../../../../components/MissionControl/HomeButton"
import FuelGauge from "../../../../components/MissionControl/FuelGauge"
import RepairScreen from "../../../../components/MissionControl/RepairScreen"
import RepairButton from "../../../../components/MissionControl/RepairButton"
import RepairLamp from "../../../../components/MissionControl/RepairLamp"
import MaintenanceScreen from "../../../../components/MissionControl/MaintenanceScreen"
import MaintenanceLever from "../../../../components/MissionControl/MaintenanceLever"
import TrophyRack from "../../../../components/MissionControl/TrophyRack"
import MaintenanceHatch from "../../../../components/MissionControl/MaintenanceHatch"
import DashboardScreen from "../../../../components/MissionControl/DashboardScreen"
import HomeScreen from "../../../../components/MissionControl/HomeScreen"
import HoloText from "../../../../components/MissionControl/HoloText"

// Images & Styling
import { BackButton } from "../../../../styles/buttons"
import ArrowIcon from "../../../../lib/icons/Arrow"
import { MissionControlLayout } from "../../../../styles/common"
import { renderTurningPhone } from "../../../../styles/helpers"
import Cockpit from "../../../../images/mission_control/background/ShipBG_Static.png"
import SpaceBackground from "../../../../images/mission_control/background/SpaceBG_Dynamic.png"

// Fetching data
import {
  updateCurrentStudentData,
  StudentActivity,
  GameSelectActivity,
} from "../../../../services/helpers"

// Globals
import { useStudentStore } from "../../../../../store"
import { UI_EVENT_NAMES } from "../../../../const_values"

// Browser check
const isBrowser = typeof window !== "undefined"

const { ALL, SPACE } = UI_EVENT_NAMES

// component
class MissionControlPage extends Component {
  constructor() {
    super()
    this.state = {
      window: {
        width: 0,
        height: 0,
      },
      urlPath: isBrowser ? window.location.pathname : null,
    }
    this.handleResize = this.handleResize.bind(this)
    this.logPageLeaving = this.logPageLeaving.bind(this)
    this.finishPageLoading = this.finishPageLoading.bind(this)
  }

  handleResize = () => {
    this.setState({
      window: {
        width: window.innerWidth,
        height: window.innerHeight,
      },
    })
  }

  checkStudentData = async () => {
    try {
      const updateSuccessResult = await updateCurrentStudentData()
      console.log("update success:", updateSuccessResult)

      if (!updateSuccessResult.success && isBrowser) {
        navigate("/student", { replace: true })
      }
    } catch (error) {
      console.error(error)
      if (isBrowser) navigate("/student", { replace: true })
    }
  }

  finishPageLoading = async () => {
    await this.checkStudentData()

    try {
      const visitActivity = new StudentActivity(
        this.state.urlPath,
        ALL.PAGE_VISITED,
        useStudentStore.getState().student.id
      )
      await visitActivity.writeToDB()
    } catch (e) {
      console.error("failed to log page visiting: ", e)
    }

    if (isBrowser) {
      window.addEventListener(
        isMobileSafari ? "pagehide" : "beforeunload",
        this.logPageLeaving
      )
    }
  }

  logPageLeaving = async (event) => {
    try {
      const leaveActivity = new StudentActivity(
        this.state.urlPath,
        ALL.PAGE_LEAVED,
        useStudentStore.getState().student.id
      )
      await leaveActivity.writeToDB()
    } catch (e) {
      console.error("failed to log page leaving: ", e)
    }
    if (event) {
      event.returnValue = ""
    }
    return ""
  }

  componentWillMount() {
    this.finishPageLoading()
  }

  componentDidMount() {
    if (isBrowser) {
      this.setState(
        {
          window: {
            width: window.innerWidth,
            height: window.innerHeight,
          },
        },
        () => window.addEventListener("resize", this.handleResize)
      )
    }
  }

  componentWillUnmount() {
    window.removeEventListener(
      isMobileSafari ? "pagehide" : "beforeunload",
      this.logPageLeaving
    )

    this.logPageLeaving()

    window.addEventListener("resize", this.handleResize)
  }

  render() {
    return (
      <>
        <Seo title="Missionszentrale" />
        <Navigation>
          <BackButton as={Link} to="/student/profile" alt="Zurück zum Profil">
            <ArrowIcon />
          </BackButton>
          <CurrentUser />
        </Navigation>
        <Content height={this.state.window.height}>
          <MissionControlLayout>
            <div className="ls-view">
              <img className="cockpit" src={Cockpit} />
              <img className="space" src={SpaceBackground} />
              <LaunchButton />
              <HomeButton />
              <DestinationScreen />
              <FuelGauge />
              <RepairScreen />
              <RepairButton />
              <RepairLamp />
              <MaintenanceScreen />
              <MaintenanceLever />
              <TrophyRack />
              <MaintenanceHatch />
              <DashboardScreen />
              <HomeScreen />
              <HoloText />
            </div>
            <div className="pt-view">{renderTurningPhone()}</div>
          </MissionControlLayout>
        </Content>
      </>
    )
  }
}

export default MissionControlPage
