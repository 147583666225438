import styled from "styled-components"
import TextBox from "../../../images/mission_control/destination/HoloTextBox.png"

export const StyledHoloText = styled.div`
  display: flex;
  position: absolute;
  width: 20.4%;
  height: 8.6%;
  background: url(${TextBox}) no-repeat;
  background-size: 100%;
  justify-content: center;
  align-items: flex-end;
  padding-bottom: 3.4vb;

  & > p.text {
    position: absolute;
    display: block;
    font-size: 2.7vb;
    color: #a8ecf8;
    margin: 0;
  }
`
