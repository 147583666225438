import styled from "styled-components"

export const StyledMaintenanceScreen = styled.div`
  display: flex;
  width: 10.8%;
  height: 18%;
  position: absolute;
  justify-content: center;
  align-items: center;

  & > img.symbol {
    width: 70%;
    height: 70%;
    /* cursor: pointer; */
  }
`
