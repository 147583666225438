import React from "react"
import PropTypes from "prop-types"

import MaintenanceLeverDisplay from "./MaintenanceLeverDisplay"

const MaintenanceLever = ({ onButtonPress }) => {
  return <MaintenanceLeverDisplay onButtonPress={onButtonPress} />
}

MaintenanceLever.propTypes = { onButtonPress: PropTypes.func }

export default MaintenanceLever
