import React from "react"

import { StyledHoloText } from "./HoloTextStyles"
import TextBox from "../../../images/mission_control/destination/HoloTextBox.png"

const HoloTextDisplay = () => {
  return (
    <StyledHoloText className="holo-text">
      <p className="text" draggable={false} >Basis-Aufgabe</p>
    </StyledHoloText>
  )
}

export default HoloTextDisplay
