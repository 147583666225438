import styled from "styled-components"

export const StyledMaintenanceHatch = styled.div`
  display: block;
  position: absolute;

  &.open {
    width: 14%;
    height: 15.5%;
  }

  &.closed {
    width: 13.9%;
    height: 15.3%;
  }

  & > img.hatch {
    width: 100%;
    height: 100%;
    z-index: 5;
    display: block;
  }
`