import React, { useState } from "react"

import { StyledMaintenanceLever } from "./MaintenanceLeverStyles"
import LeverIcon from "../../../images/mission_control/maintenance/WartungHebel_Dynamic.png"

const MaintenanceLeverDisplay = ({ onButtonPress }) => {
  const [buttonPressed, setButtonPressed] = useState(false)

  const handleButtonPress = () => {
    setButtonPressed((prev) => !prev)
    if (onButtonPress) onButtonPress()
  }

  const handleButtonRelease = () => {}

  return (
    <StyledMaintenanceLever
      className={`maintenance-lever${buttonPressed ? " down" : " up"}`}
      type="button"
      onTouchStart={handleButtonPress}
      onMouseDown={handleButtonPress}
      onTouchEnd={handleButtonRelease}
      onMouseUp={handleButtonRelease}
    >
      <img className="icon" src={LeverIcon} draggable={false} />
    </StyledMaintenanceLever>
  )
}

export default MaintenanceLeverDisplay
