import React, { useState } from "react"

import { StyledLaunchButton } from "./LaunchButtonStyles"
import LaunchButton from "../../../images/mission_control/destination/MainButton_Dynamic.png"
import LaunchBase from "../../../images/mission_control/destination/MainButtonBase.png"

const LaunchButtonDisplay = ({ onButtonPress }) => {
  const [buttonPressed, setButtonPressed] = useState(false)

  const handleButtonPress = () => {
    setButtonPressed(true)
    if (onButtonPress) onButtonPress()
  }

  const handleButtonRelease = () => {
    setButtonPressed(false)
  }

  return (
    <StyledLaunchButton
      className={`btn-launch${buttonPressed ? " pressed" : ""}`}
      type="button"
      onTouchStart={handleButtonPress}
      onMouseDown={handleButtonPress}
      onTouchEnd={handleButtonRelease}
      onMouseUp={handleButtonRelease}
    >
      <img className="launch-btn" src={LaunchButton} draggable={false} />
      <img className="launch-base" src={LaunchBase} draggable={false} />
    </StyledLaunchButton>
  )
}

export default LaunchButtonDisplay
