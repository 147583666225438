import React from "react"

import { StyledHomeScreen } from "./HomeScreenStyles"
import ScreenBackground from "../../../images/mission_control/home/HomeScreenBG.png"

const HomeScreenDisplay = () => {
  return (
    <StyledHomeScreen className="home-screen">
      <img className="display" src={ScreenBackground} draggable={false} />
    </StyledHomeScreen>
  )
}

export default HomeScreenDisplay
