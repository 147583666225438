import React from "react"

import { StyledRepairScreen } from "./RepairScreenStyles"
import BlueContentScreen from "../../../images/mission_control/repair/ErsatzteileScreenContentBlue.png"
import RedContentScreen from "../../../images/mission_control/repair/ErsatzteileScreenContentRed.png"
import RedFlame from "../../../images/mission_control/repair/ErsatzteileScreenContentRedBlinkingFlame.png"

const RepairScreenDisplay = () => {
  return (
    <StyledRepairScreen className="repair-screen">
      <img className="display" src={RedContentScreen} draggable={false} />
      <img className="flame" src={RedFlame} draggable={false} />
    </StyledRepairScreen>
  )
}

export default RepairScreenDisplay
