import styled from "styled-components"

export const StyledHomeButton = styled.button`
  display: block;
  border: none;
  background: none;
  width: 6.4%;
  height: 9.9%;
  position: absolute;
  padding: 0 0;

  & > .icon {
    height: 75.5%;
    width: 67.6%;
    z-index: 5;
    position: absolute;
    display: block;
    top: 18.3%;
    left: 10%;
    transition: transform 0.1s ease-in-out, filter 0.2s ease-in-out;
  }

  &.pressed > .icon {
    transform: translate(7.8%, -2.8%);
    filter: brightness(70%);
  }

  & > .base {
    height: 75.5%;
    width: 67.6%;
    z-index: 4;
    position: absolute;
    display: block;
    top: 15.5%;
    left: 17.8%;
  }
`
